/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@use 'swiper/scss' as *;
@use 'swiper/scss/pagination' as *;
@import 'theme';
@import 'material';
@import 'normalize';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

@font-face {
  font-family: 'Meiryo UI';
  font-weight: normal;
  src: local('Meiryo UI'), url(/assets/fonts/MeiryoUI.woff2) format('woff2');
  // 일어, 한자, 일본어 한자, 영문 전각, 기호
  /* unicode-range: U+3041-4096, U+30A0-30FF, U+3400-4DB5, U+4E00-9FCB, U+F900-FA6A,
    U+2E80-2FD5, U+FF5F-FF9F, U+31F0-31FF, U+3220-3243, U+3280-337F, U+FF01-FF5E; */
}

.material-symbols-outlined {
  user-select: none;
  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 48;
}

html {
  font-family: 'Noto Sans JP', 'Noto Sans KR';
  color: $font-color;
}

* {
  -webkit-tap-highlight-color: transparent;
}

// 모바일 앱의 경우
.mobile-app {
  * {
    // 드래그로 요소 선택 불가
    user-select: none;
    // 링크 롱 터치 불가
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
}

// 크롬 스크롤 복원용 래퍼
main[wrapper] {
  min-height: 100vh;
  background-color: #fff;
}

// ios safearea top, bottom, left, right
[safearea] {
  @extend [safe-top];
  @extend [safe-bottom];
  @extend [safe-side];
}

// ios safearea top
[safe-top] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-top: constant(safe-area-inset-top);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
  }
}

// ios safearea bottom
[safe-bottom] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-bottom: constant(safe-area-inset-bottom);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

// ios safearea left, right
[safe-side] {
  @supports (padding-top: constant(safe-area-inset-top)) {
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
  }
  @supports (padding-top: env(safe-area-inset-top)) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.swiper-pagination-bullet {
  width: 30px;
  height: 5px;
  background-color: #fff;
  opacity: 1;
  border-radius: 0;
  transition: width 0.2s, background-color 0.2s;
  &.swiper-pagination-bullet-active,
  &:hover {
    width: 60px;
    background-color: $theme-secondary-500;
  }
}

.mat-accordion {
  .mat-expansion-panel {
    background-color: transparent;
    box-shadow: none;
  }
}

mat-sidenav-container .mat-drawer-backdrop {
  position: fixed;
}
mat-sidenav {
  mat-expansion-panel-header.mat-expansion-panel-header {
    padding: 0;
    padding-right: 4px;
    &:hover,
    &:focus {
      background-color: #1a365e !important;
    }
    &.mat-expanded {
      height: 48px;
    }
  }
  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0 22px 8px;
  }

  span.mat-expansion-indicator {
    &::after {
      padding: 4px;
      color: #fff;
    }
  }
}

span.mat-expansion-indicator {
  &::after {
    padding: 5px;
    color: $theme-primary-500;
    border-width: 0 3px 3px 0;
    border-radius: 3px;
  }
  transform-origin: center 70%;
  margin-top: -6px;
}

mat-sidenav {
  .mat-accordion {
    > .mat-expansion-panel-spacing {
      margin: 0 !important;
    }
  }
  mat-expansion-panel-header.mat-expansion-panel-header {
    &:hover,
    &:focus {
      background-color: transparent !important;
    }
  }
  span.mat-expansion-indicator {
    &::after {
      padding: 8px;
      color: #fff;
    }
  }
}

.mat-drawer-container {
  color: $font-color;
}

input,
textarea {
  border: 1px solid #333;
  box-sizing: border-box;
  background: transparent;
  padding: 12px 16px;
  color: #333;
  caret-color: currentColor;
  &:placeholder-shown {
    border-color: #999;
  }
  &::placeholder {
    color: #949494;
    opacity: 1;
  }
  &:disabled {
    border-color: #999;
    color: #949494;
    background-color: #f6f6f6;
  }
  outline: none;
  resize: none;
}

select {
  padding: 12px 16px;
  border: solid 1px #999;
  color: #666;
  background-color: transparent;
  font-size: 14px;
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: calc(100% - 8px);
  background-size: 18px;
  background-image: url(/assets/imgs/ic_expand_more.svg);
  background-color: transparent;

  &:required:invalid {
    color: #949494;
  }

  option {
    padding: 0;
    &:disabled {
      color: #949494;
    }
  }
}

app-my-account-form {
  mat-radio-button {
    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      width: 16px;
      height: 16px;
    }
  }
}

mat-checkbox {
  font-size: 16px;
  font-weight: 500;
  &[large] {
    font-size: 20px;
    .mat-checkbox-inner-container {
      margin-left: -6px;
      width: 22px;
      height: 22px;
    }
  }

  .mat-checkbox-inner-container {
    margin-right: 12px;
  }
  .mat-checkbox-frame {
    border-width: 1px;
    border-color: #949494;
  }
}

app-ordr-form {
  .mat-checkbox-inner-container {
    margin-left: 0;
    margin-right: 4px;
  }
  [large] {
    .mat-checkbox-inner-container {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

app-register {
  .accept-all {
    mat-checkbox {
      .mat-checkbox-inner-container {
        width: 24px;
        height: 24px;
      }
    }
  }
}

app-cart {
  mat-checkbox.select-all {
    .mat-checkbox-inner-container {
      margin-right: 8px;
    }
    .mat-checkbox-inner-container {
      width: 24px;
      height: 24px;
    }
  }
}

.dialog-page-panel {
  max-width: 600px;
  max-height: 80vh;

  @media (max-width: 600px) {
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;

    mat-dialog-container {
      border-radius: 0;
    }
  }
}

.pg-dialog {
  @extend .dialog-page-panel;
  max-width: 800px;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  mat-dialog-container {
    padding: 0;
  }

  #ifrWrapper {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;

    mat-dialog-container {
      overflow: hidden;
    }
  }
}

.dialog-page-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

app-calendar {
  .calendar-html {
    width: 100%;
    font-size: 13px;
    letter-spacing: -0.6px;
    color: #fff;
    cursor: pointer;

    @media (max-width: 600px) {
      font-size: 7px;
    }
  }

  .html-time {
    padding: 0 8px;
    height: 20px;
    background-color: #45484e;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .html-time::before {
    content: '●';
    margin-right: 4px;
    color: $theme-primary-500;
  }

  .html-title {
    padding: 0 8px;
    height: 20px;
    background-color: $theme-primary-500;
    overflow: hidden;

    @media (max-width: 600px) {
      padding: 1px;
      font-size: 7px;
      height: 14px;
      line-height: 14px;
    }
  }
}

[kumahanin-select] {
  display: inline-block;
  border: 1px solid $border-color-light;
  padding: 7px;
  min-width: 40px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  background-color: #fff;

  .mat-select-trigger {
    height: 0.875rem;
  }
}

[kumahanin-button] {
  padding: 8px 16px;
  font-size: 0.875rem;
  background-color: $theme-primary-500;
  color: #fff;
}

app-intro-mindan,
app-history-myeongseong {
  .template {
    .create-date {
      position: absolute;
      top: 80px;
      right: 0;
      font-size: 20px;
      color: #000;
    }

    h2 {
      position: relative;
      color: #051a2a;
      font-size: 30px;
      font-weight: 500;
      padding-top: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid #707070;
      margin-bottom: 26px;
      &::before {
        content: '';
        width: 24px;
        height: 6px;
        background-color: #5d8eeb;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    ul {
      margin-bottom: 42px;
      font-family: Meiryo UI;
      font-size: 14px;
      line-height: 1.2;
      overflow: hidden;
      li {
        margin-left: 22px;
        &::marker {
          content: '—  ';
        }
      }
    }

    ol {
      padding-left: 26px;
      li {
        list-style-type: decimal-leading-zero;
        font-family: Meiryo UI;
        font-size: 14px;
        color: #2d2f34;
        line-height: 1.25;
        &::marker {
          color: #707070;
        }
      }
    }

    img {
      width: 282px;
      aspect-ratio: 1;
      border-radius: 32px;
      background-color: #707070;
      flex-shrink: 0;
      object-fit: cover;
      user-select: none;
      -webkit-user-drag: none;
    }

    span {
      color: #000;
    }

    .position {
      margin-top: 16px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      line-height: 1.25;
    }

    .name {
      font-size: 20px;
      text-align: center;
      line-height: 1.25;
    }

    .hall-layout {
      margin-bottom: 28px;
      span {
        font-size: 14px;
        line-height: 1.25;
      }
    }

    table {
      font-family: Meiryo UI;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 60px;
      thead {
        th {
          height: 24px;
          color: #fff;
          border: 3px solid #fff;
          background-color: #f38611;
          &:first-child {
            border-left: none;
            width: 50px;
          }
        }
      }
      tbody {
        td {
          height: 24px;
          text-align: center;
          border: 3px solid #fff;
          background-color: #f2f7fb;
          &:first-child {
            border-left: none;
            background-color: #215fab;
            color: #fff;
          }
        }
      }
    }

    p {
      font-family: Meiryo UI;
      font-size: 14px;
      color: #2d2f34;
      margin-bottom: 52px;
      line-height: 1.25;
    }

    .square {
      position: relative;
      box-sizing: border-box;
      width: 21px;
      height: 21px;
      border: 7px solid $theme-primary-500;
      align-self: center;
      margin-right: 12px;
      flex-shrink: 0;
      &::before {
        content: '';
        width: 7px;
        height: 7px;
        background-color: $theme-secondary-500;
        position: absolute;
        top: -7px;
        left: -7px;
      }
    }
    mat-accordion {
      mat-expansion-panel {
        background-color: #f7f9fe !important;
        border-radius: 30px !important;
        margin-bottom: 32px;
        mat-expansion-panel-header.mat-expansion-panel-header {
          box-sizing: border-box;
          border: 8px solid #eff5ff;
          background-color: #fff;
          border-radius: 30px;
          height: 98px;
          font-size: 20px;
          font-weight: 500;
          color: $theme-primary-500;
          line-height: 1;
          padding-right: 36px;
          user-select: none;
        }
        p {
          padding: 8px;
          padding-top: 24px;
          padding-bottom: 40px;
          margin-bottom: 0;
        }
      }
    }

    .title {
      text-align: center;
    }

    .subtitle {
      text-align: right;
    }
  }
}
.swiper-pagination-bullet-active,
.swiper-pagination-bullet:hover {
  background-color: #5d8eeb !important;
  width: 30px !important;
  height: 10px !important;
  border-radius: 5px !important;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
